<template>
  <WorktimeCreate />
</template>

<script>
import WorktimeCreate from "@/components/worktime/WorktimeCreate";
export default {
  name: "Worktime",
  components: { WorktimeCreate }
};
</script>

<style scoped></style>
