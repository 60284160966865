<template>
  <div v-if="!loading" class="mx-auto">
    <v-card elevation="0" class="rounded-0">
      <v-card-text>
        <div
          class="header pa-4"
          @click="goToPendingWorkList"
          v-if="worktimePendingCount > 0"
        >
          <h1>アウトしていない勤怠が {{ worktimePendingCount }} 件あります。</h1>
        </div>

        <div v-if="hideFromChild == false">
            <p class="title">
              新しい勤怠を作ります。勤怠はタスク/日付を選択してから入力してください。
            </p>
        </div>
        <v-row v-if="hideFromChild == false">
          <v-col cols="3" sm="3" class="text-right">日付</v-col>
          <v-col cols="9" sm="9">
            <v-dialog
              ref="dialog"
              v-model="modal"
              persistent
              :return-value.sync="date"
              width="290px"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  class="form-field"
                  v-model="computedDateFormatted"
                  placeholder="未選択"
                  outlined
                  dense
                  readonly
                  v-bind="attrs"
                  v-on="on"
                  hide-details="auto"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="date"
                scrollable
                locale="ja"
                :day-format="formatDatePicker"
              >
                <v-spacer></v-spacer>
                <v-btn text color="primary" @click="modal = false"
                  >キャンセル</v-btn
                >
                <v-btn text color="primary" @click="changeDate(date)">OK</v-btn>
              </v-date-picker>
            </v-dialog>
          </v-col>
        </v-row>

        <v-card v-if="date" class="mt-5 bg-blk">
          <v-card-text v-if="hideFromChild == false">
            タスクを選択
            <v-autocomplete
              class="form-field"
              outlined
              dense
              hide-details="auto"
              placeholder="タスクIDかタスク名をご入力ください。"
              :items="tasks"
              v-model="data.task"
              item-text="name"
              item-value="id"
              return-object
            ></v-autocomplete>
          </v-card-text>
        </v-card>

        <v-card v-if="hideFromChild != false" class="mt-5 bg-blk">
          <v-row class="confirm-sec">
            <v-col cols="12">
              <div class="wt-id">WT-未設定</div>
              <strong>
                {{ data.task.customer_name }} - {{ data.task.site_name }}
              </strong>
              <div class="service-name">{{ data.task.service_type }}</div>
            </v-col>
            <v-col cols="12">
              <v-row>
                <v-col cols="3" class="text-right">月日：</v-col>
                <v-col cols="9">{{ computedDateFormatted }}</v-col>
              </v-row>
              <v-row class="mt-0">
                <v-col cols="3" class="text-right">タイプ：</v-col>
                <v-col cols="9">{{ getClockType(data.type) }}</v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-card>

        <WorktimeCreateForm
          :data="data"
          :is-edit="false"
          v-on:hidelparent="isSave($event)"
        />
    </v-card-text>
  </v-card>
  </div>
</template>

<script>
import WorktimeCreateForm from "@/components/layout/worktime/form/WorktimeCreateForm";
import { mapGetters } from "vuex";
import dayjs from "dayjs";
export default {
  name: "WorktimeCreate",
  components: { WorktimeCreateForm },
  created() {
    this.loading = false;
    this.$store.dispatch("COUNT_PENDING_WORKTIME");
  },
  computed: {
    ...mapGetters(["tasks", "user", "worktimePendingCount"]),
    computedDateFormatted() {
      return this.formatDate(this.date);
    },
  },
  data() {
    return {
      loading: false,
      date: null,
      modal: false,
      task: null,
      data: {
        type: null,
        clock_in: null,
        clock_out: null,
        tag: null,
        memo: null,
        resttime: [],
        task: null,
      },
      hideFromChild: false,
      types: [
        // { value: 0, text: "現場勤務（リ）", disabled: true },
        { value: 1, text: "現場勤務（個）" },
        { value: 2, text: "運転・調整" },
        { value: 3, text: "参加" },
        { value: 4, text: "タスク" },
        { value: 5, text: "その他" },
      ],
    };
  },

  methods: {
    changeDate(date) {
      let params = {};
      params.date = date;
      this.$store.dispatch("GET_ALL_TASKS_BY_DATE", params);
      this.$refs.dialog.save(date);
    },
    formatDate(date) {
      if (!date) return null;

      const [year, month, day] = date.split("-");
      return `${year}年${month}月${day}日`;
    },
    formatDatePicker(e) {
      return dayjs(e).format("D");
    },
    isSave(variable) {
      this.hideFromChild = variable;
    },
    getClockType(type_id) {
      return this.types.find((e) => e.value === type_id).text || null;
    },
    goToPendingWorkList() {
      this.$router.push({
        name: "worktime",
        query: { pending: 1 },
      });
    },
  },
};
</script>

<style scoped>
.header {
  background: #ff8585;
  cursor: pointer;
}
.header h1 {
  color: #fff;
  font-size: 14px;
  text-align: center;
  line-height: 1;
}
.title {
  font-size: 14px !important;
}
.theme--light.v-input,
.theme--light.v-input input {
  font-size: 12px;
}
.bg-blk {
  background: #fffff0;
  border: 1px solid #bdbdbd;
  border-radius: 5px;
  box-shadow: 0px 0px !important;
}
.confirm-sec {
  padding: 10px;
  font-size: 14px;
}
.confirm-sec .wt-id {
  font-size: 12px;
  color: #828282;
}
.confirm-sec strong {
  font-size: 15px;
}
</style>
